import React,{useEffect,useState} from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import PageHeader from "@components/pageheader";
import AboutArea from "@containers/index-processing/about-area"
import ContactArea from "@containers/index-infotechno/contact-area"
import { FormattedMessage,useIntl } from "gatsby-plugin-react-intl"
import Resizer from "react-image-file-resizer";
import slide1 from "@assets/images/newdesign/1.jpg"
import slide2 from "@assets/images/newdesign/2.jpg"
import slide3 from "@assets/images/newdesign/3.jpg"
import slide4 from "@assets/images/newdesign/4.jpg"

const BuildingDesign = ({location,pageContext}) => {

const [newImage, setnewImage] = useState("");



// const resizeFile = (file) =>
//   new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       300,
//       300,
//       "jpg",
//       100,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       "base64"
//     );
//   });
  

//   useEffect(() => {
    
  
//         const file = slide1;
//         const image = resizeFile(file);
//         console.log(image);
      
    
//   }, []);
  const content = [
    {
      image: slide1,
    },
    {
      image: slide2,
    },
    {
      image: slide3,
    },
    {
      image: slide4,
    },
  ]
  
 


  const srviceTexts = [
    {
      text: (
        <FormattedMessage id="preparing_preliminary_architectural_study_for_the_project" />
      ),
    },
    {
      text: <FormattedMessage id="preparing_architectural" />,
    },
    {
      text: <FormattedMessage id="Preparing_schedules" />,
    },
    {
      text: <FormattedMessage id="Conducting_necessary" />,
    },
    {
      text: <FormattedMessage id="Provide_screenshots_project_with_3D_output" />,
    },
  ]


  const intl = useIntl()
  const serviceTitles = intl.formatMessage({
    id: "building_design",
  })

const  fileChangedHandler=(event)=> {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          slide1,
          430,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            setnewImage(uri)
            console.log(newImage,"new image");
          },
          "base64"
        );
      } catch (err) {
        console.log(err);
      }
    }
  }
  return ( 
    <Layout location={location}>
    <Seo title="Services" />
    <Header />
    <main className="site-wrapper-reveal">
      {/* <HeroArea /> */}
	  <PageHeader
			pageContext={pageContext}
			location={location}
			title="IT Services"
      pageTitle="services"
		/>
      <AboutArea
        content={content}
        srviceTexts={srviceTexts}
        serviceTitles={serviceTitles}
        studyRequestUrl="/sudy-request-newdesign"
        isStudyRequest={true}
        buttonTextRequest="study_request"
        stopPointer="stopPointer"
        
      />
      {/* <input type="file" onChange={fileChangedHandler} />
        <img src={newImage} alt="" /> */}

      <ContactArea />
    </main>
    <Footer />
  </Layout>
   );
}
 
export default BuildingDesign;


